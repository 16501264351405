import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 385px;
  height: 100%;
  position: relative;
  padding: 10px;
  border: 1px solid #0000000d;
  .icon {
    margin-bottom: 20px;
  }

  p {
    font-size: var(--font-size-20);
    color: var(--darkest);
  }

  img {
    height: 85px;
    width: auto;
  }

  .body {
    font-size: var(--font-size-20);
    margin-bottom: 52px;
    height: 100%;
  }

  .footer {
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    display: flex;
    margin-bottom: 0;
    margin-bottom: 5px;
    p {
      margin-bottom: 0px;
      width: auto;
    }

    svg {
      height: 14px;
      margin-left: 7px;
      margin-bottom: 0;
    }
  }

  &:hover {
    cursor: pointer;

    .footer {
      color: var(--titan-blue-3);
    }
  }

  @media (max-width: 1280px) {
    min-height: 450px;

    .footer {
      bottom: 10px;
      margin: 0;
    }
  }

  @media (max-width: 950px) {
    min-height: 500px;

    .footer {
      bottom: 20px;
    }

    .title {
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: var(--font-size-18);
      }
    }
  }
`;

export { CardContainer };
