import React from 'react';

const RightArrow = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.296096 10.0998L14.2186 10.0998L7.82362 16.2488L9.4481 17.7998L18.6001 8.9998L9.4481 0.199804L7.83506 1.7508L14.2186 7.8998L0.296096 7.8998L0.296096 10.0998Z"
      fill="black"
    />
  </svg>
);

export default RightArrow;
