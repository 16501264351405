import React, { useState } from 'react';
import { Col, Row } from 'components/Core/Grid';
import { STIcon } from 'components/Brand/Icons/STIcon';
import RightArrow from 'images/inline/right-arrow';
import LeftArrow from 'images/inline/left-arrow';
import RichText from 'components/RichText';
import WebsiteImage from 'components/WebsiteImage';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import { FeatureDetailContainer } from './styles';
import { reduceContentValues } from 'utils/text';

const FeatureDetail = ({ activeIndex, setActiveIndex, cards }) => {
  const [isFooterClick, setIsFooterClick] = useState(false);

  return cards.map((card, index) => {
    const { title, icon, image } = card || {};

    const cardsLength = cards.length;
    const prevCardIndex = index === 0 ? cardsLength - 1 : index - 1;
    const nextCardIndex = index === cardsLength - 1 ? 0 : index + 1;
    const prevCard = cards[prevCardIndex];
    const nextCard = cards[nextCardIndex];

    const handleClick = (selectedIndex, type) => {
      dataLayer.push({
        event: 'cta clicked',
        cta_text: cards[selectedIndex]?.title?.content?.raw
          ? reduceContentValues(JSON.parse(cards[selectedIndex]?.title?.content?.raw))
          : 'Back',
        cta_position: 'explorecard',
      });
      setActiveIndex(selectedIndex);
      setIsFooterClick(type === 'footer' || false);
    };

    return (
      <FeatureDetailContainer
        active={activeIndex === index}
        isFooterClick={isFooterClick}
        key={`feature-detail-${index}`}
      >
        <Row className="content-container" gutter={10}>
          <Col xs={13} className="content">
            <div className="icon-title">
              <img src={icon?.image?.file?.url} alt={icon?.image?.alt} />
              <RichText content={title.content} className="title" />
            </div>
            <div className="copy">
              <ContentfulRichText richText={card.copy} className="main-copy" />
            </div>
          </Col>
          <Col xs={11} className="image">
            <WebsiteImage
              image={image?.image}
              styles={{ maxWidthMobile: '550px', maxHeightMobile: '550px' }}
            />
          </Col>
        </Row>
        <div className="footer">
          <div className="footer-links">
            <div>
              <button
                className="footer-prev"
                type="button"
                onClick={() => handleClick(prevCardIndex, 'footer')}
              >
                <LeftArrow />
                <RichText content={prevCard?.title?.content} />
              </button>
            </div>
            <div>
              <STIcon
                className="icon-appstore"
                type="icon-appstore"
                onClick={() => handleClick(null)}
              />
            </div>
            <div>
              <button
                className="footer-next"
                type="button"
                onClick={() => handleClick(nextCardIndex, 'footer')}
              >
                <RichText content={nextCard?.title?.content} />
                <RightArrow />
              </button>
            </div>
          </div>
        </div>
      </FeatureDetailContainer>
    );
  });
};

export default FeatureDetail;
