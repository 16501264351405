import React from 'react';

const LeftArrow = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7039 7.90013H4.78142L11.1764 1.75113L9.5519 0.200134L0.399902 9.00013L9.5519 17.8001L11.1649 16.2491L4.78142 10.1001H18.7039V7.90013Z"
      fill="black"
    />
  </svg>
);

export default LeftArrow;
