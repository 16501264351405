import React from 'react';
import CardsGroup from 'components/Core/Cards/CardsGroup';
import DynamicFeatureCard from '../Card';
import { FeatureCardsContainer } from './styles';

const FeatureCards = ({ activeIndex, cards, setActiveIndex }) => (
  <FeatureCardsContainer activeIndex={activeIndex}>
    <CardsGroup cols={4}>
      {cards?.map((card, index) => (
        <DynamicFeatureCard
          card={card}
          setActiveIndex={setActiveIndex}
          index={index}
          key={`cards-${index}`}
        />
      ))}
    </CardsGroup>
  </FeatureCardsContainer>
);

export default FeatureCards;
