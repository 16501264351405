import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import WebsiteImage from 'components/WebsiteImage';
import RichText from 'components/RichText';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import { Container, StyledCard } from './styles';

const ScrollableMobileFeatureCards = ({ items }) => {
  const [slider, setSlider] = useState(null);
  const [autoPlay, setAutoPlay] = useState(true);

  const sliderSettings = {
    dots: false,
    infinite: false,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: false,
    draggable: true,
    swipeToSlide: true,
    speed: 500,
    onSwipe: () => setAutoPlay(false),
  };

  useEffect(() => {
    let interval;

    if (autoPlay && slider) {
      let currentIndex = 0;
      let slideDir = 'forward';

      interval = setInterval(() => {
        const lastSlide = currentIndex === items.length - 1;
        const firstSlide = currentIndex === 0;

        if (lastSlide) {
          slideDir = 'backward';
        }

        if (firstSlide) {
          slideDir = 'forward';
        }

        if (slideDir === 'forward') {
          currentIndex += 1;
          slider.slickGoTo(currentIndex);
        } else {
          currentIndex -= 1;
          slider.slickGoTo(currentIndex);
        }
      }, 3000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [autoPlay, slider]);

  return (
    <Container>
      <Slider className="slider-wrapper" ref={(c) => setSlider(c)} {...sliderSettings}>
        {items.map((item, index) => (
          <StyledCard key={`item-${index}`}>
            <WebsiteImage {...item.image} />
            <div className="copy">
              <RichText content={item?.title?.content} />
              <ContentfulRichText
                richText={{
                  content: item?.description || item?.copy?.content,
                  listIcon: item?.copy?.listIcon?.image?.file?.url || item?.icon?.image?.file?.url,
                }}
              />
            </div>
          </StyledCard>
        ))}
      </Slider>
    </Container>
  );
};

export default ScrollableMobileFeatureCards;
