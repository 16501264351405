import React, { useState } from 'react';
import { SectionLayout } from 'components/Sections/SectionLayout';
import { getEntry } from '../Contentful/ContentfulLayout/utils/entriesHelpers';
import ScrollableMobileFeatureCards from './ScrollableMobileFeatureCards';
import FeatureDetail from './FeatureDetail';
import FeatureCards from './FeatureCards';
import RichText from '../RichText';
import {
  DynamicFeaturesContainer,
  DynamicFeaturesMobileContainer,
  DynamicFeaturesTitle,
} from './styles';

const DynamicFeatures = ({ entries }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const cards = [];

  for (let step = 1; step < 5; step += 1) {
    const types = ['icon', 'excerpt', 'image', 'copy', 'title', 'link'];

    const object = {};

    types.forEach((type) => {
      entries.forEach((entry) => {
        if (entry.templateId === `card-${step}-${type}`) {
          object[type] = entry;
        }
      });
    });

    cards.push(object);
  }

  return (
    <SectionLayout>
      <DynamicFeaturesTitle>
        <RichText content={getEntry('title', entries)?.content} />
      </DynamicFeaturesTitle>

      <DynamicFeaturesContainer className="min-lg">
        <FeatureCards activeIndex={activeIndex} setActiveIndex={setActiveIndex} cards={cards} />
        <FeatureDetail activeIndex={activeIndex} cards={cards} setActiveIndex={setActiveIndex} />
      </DynamicFeaturesContainer>

      <DynamicFeaturesMobileContainer className="max-lg">
        <ScrollableMobileFeatureCards items={cards} />
      </DynamicFeaturesMobileContainer>
    </SectionLayout>
  );
};

export default DynamicFeatures;
