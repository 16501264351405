import styled from 'styled-components';

const DynamicFeaturesContainer = styled.div`
  height: 480px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1280px) {
    height: 480px;
  }

  @media (max-width: 950px) {
    height: 500px;
  }
`;

const DynamicFeaturesMobileContainer = styled.div`
  .custom-accordion-panel {
    &.active,
    &.inactive {
      border: 1px solid var(--grey-3);
      transition: none;
      box-shadow: none;
      border-radius: 0;
    }
  }
  .slick-slide {
    margin: 0 10px;
  }
  img[alt='minus-icon'] {
    display: none;
  }
  svg[alt='open-icon'] {
    width: 21px;
  }
  ul {
    li {
      margin-bottom: 10px;
      color: var(--darkest);
      &:before {
        height: 13px;
        width: 19px;
        margin-top: 5px;
        margin-right: 20px;
      }
      p {
        margin-left: 0;
        color: var(--darkest);
      }
    }
  }
`;

const DynamicFeaturesTitle = styled.div`
  text-align: center;
  margin-bottom: 33px;
`;

export { DynamicFeaturesContainer, DynamicFeaturesMobileContainer, DynamicFeaturesTitle };
