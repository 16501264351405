import styled from 'styled-components';

const Container = styled.div`
  .left-align-slick > .slick-list > .slick-track {
    margin-left: 0px !important;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-prev:before,
  .slick-next:before {
    content: '' !important;
  }

  .slick-prev {
    left: -100px;

    img {
      transform: rotate(180deg);
    }
  }

  .slick-next {
    right: -80px;
  }

  .slick-disabled {
    display: none !important;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      margin: 0 10px;
      display: flex;

      & > div {
        height: 100%;

        .slider-slide {
          height: 100%;
        }
      }
    }
  }

  .slider-wrapper {
    margin-left: -10px;
    margin-right: 0;
    padding: 0 0 0 28px;
  }
`;

const StyledCard = styled.div`
  display: flex !important;
  flex-direction: column;
  height: 100%;

  .website-image {
    width: calc(100% - 40px);
    margin: 0 auto;
  }

  .copy {
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);
    padding: 135px 20px 20px 20px;
    margin-top: -105px;
    height: 100%;

    h3 {
      margin: 0 auto;
      width: max-content;
    }
  }
`;

export { Container, StyledCard };
