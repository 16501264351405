import React from 'react';
import optionsEmbeds from './utils/optionsEmbeds';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import RichTextHyperLink from 'components/Core/HyperLink/RichTextHyperLink';
import RichTextEmbeddedAsset from './components/RichTextEmbeddedAsset';
import RichTextParagraphBlock from './components/RichTextParagraphBlock';
import RichTextQuote from './components/RichTextQuote';
import { reduceContentValues } from 'utils/text';

const bareOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <span className="bold">
        <b>{text}</b>
      </span>
    ),
    [MARKS.ITALIC]: (text) => (
      <span className="bold">
        <em>{text}</em>
      </span>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node) => (
      <RichTextHyperLink href={node.data.uri}>
        {reduceContentValues(node?.content)}
      </RichTextHyperLink>
    ),
    [BLOCKS.HEADING_1]: (node, children) => <>{children}</>,
    [BLOCKS.HEADING_2]: (node, children) => <>{children}</>,
    [BLOCKS.HEADING_3]: (node, children) => <>{children}</>,
    [BLOCKS.HEADING_4]: (node, children) => <>{children}</>,
    [BLOCKS.HEADING_5]: (node, children) => <>{children}</>,
    [BLOCKS.HEADING_6]: (node, children) => <>{children}</>,
    [BLOCKS.QUOTE]: (node, children) => <RichTextQuote>{children}</RichTextQuote>,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => optionsEmbeds(node),
    [BLOCKS.EMBEDDED_ASSET]: (node) => <RichTextEmbeddedAsset node={node} />,
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <RichTextParagraphBlock node={node} children={children} bareOptions />
    ),
  },
};

export default bareOptions;
