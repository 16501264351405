import styled from 'styled-components';

const FeatureCardsContainer = styled.div`
  position: absolute;
  padding: 0 12px;
  .card-group--row {
    margin: 0px;
  }
  .card-group--item {
    margin: 10px !important;
    &:hover {
      box-shadow: 0px 15px 20px 0px #0b1a211a;
    }
  }
  ${({ activeIndex }) => {
    if (activeIndex !== null) {
      return `
        top: -500px;
        transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
      `;
    }
    return `
      top: 0; 
      transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    `;
  }}
  @media (max-width: 1280px) {
    ${({ activeIndex }) => {
      if (activeIndex !== null) {
        return `
          top: -525px;
      `;
      }
    }}
  }
`;

export { FeatureCardsContainer };
