import React, { useState } from 'react';
import WebsiteImage from 'components/WebsiteImage';
import RichText from 'components/RichText';
import ArrowRight from 'images/inline/right-arrow';
import { CardContainer } from './styles';
import { reduceContentValues } from 'utils/text';
import bareOptions from 'components/RichText/options/bareOptions';

const DynamicFeatureCard = ({ card, index, setActiveIndex }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { icon, excerpt, title, link } = card || {};

  const cardClick = () => {
    dataLayer.push({
      event: 'cta clicked',
      cta_text: title?.content?.raw ? reduceContentValues(JSON.parse(title.content.raw)) : '',
      cta_position: 'explorecard',
    });
    setActiveIndex(index);
  };

  return (
    <CardContainer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      $isHovering={isHovering}
      onClick={cardClick}
    >
      <div className="icon">
        <WebsiteImage {...icon} imgStyle={{ height: '65px', width: 'auto' }} />
      </div>
      <h4 className="title">
        <RichText content={title.content} options={bareOptions} />
      </h4>
      <p className="body">
        <RichText content={excerpt.content} options={bareOptions} />
      </p>
      <div className="footer">
        {link && (
          <>
            <RichText content={link.content} /> <ArrowRight alt="right-arrow" />
          </>
        )}
      </div>
    </CardContainer>
  );
};

export default DynamicFeatureCard;
