import styled from 'styled-components';

const FeatureDetailContainer = styled.div`
  position: absolute;
  height: 480px;
  width: 100%;
  h5 {
    margin: 0 0 0 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--darkest);
    font-size: var(--font-size-h4);
    margin: 0;
    width: 100%;
  }
  ul {
    li {
      margin-bottom: 10px;
      &:before {
        height: 13px;
        width: 19px;
        margin-top: 7px;
        margin-right: 20px;
      }
      p {
        margin: 0 0 4px 0;
        font-size: var(--font-size-20);
        color: var(--darkest);
      }
    }
  }
  .styled-rich-text {
    margin-top: 5px;
  }
  .image {
    img {
      width: 100% !important;
      right: 0;
      margin: 0 auto;
    }
  }
  button {
    background: none;
    border: none;
    z-index: 1;
    &:hover {
      cursor: pointer;
      path {
        fill: var(--titan-blue-3);
      }
    }
  }
  .icon-title {
    display: flex;
    align-items: center;
  }
  .copy {
    margin-top: 30px;
    padding-right: 48px;
  }
  .main-copy {
    margin: 0;
  }
  .content-container {
    height: 375px;

    .image {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding-top: 25px;
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  img {
    height: 85px;
    margin-right: 20px;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--darkest);
      display: inline;
      font-size: var(--font-size-20);
      font-weight: 500;
      letter-spacing: 0.01em;
      margin: 0 10px;
      &:empty {
        display: none;
      }
      &:hover {
        color: var(--titan-blue-3);
      }
    }
    .footer-links {
      display: flex;
      justify-content: space-between;
      > div {
        width: 20%;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      > div:first-of-type {
        width: 40%;
        justify-content: left;
      }
      > div:last-of-type {
        width: 40%;
        justify-content: flex-end;
      }
      .footer-prev,
      .footer-next {
        svg {
          padding-top: 5px;
          width: 19px;
        }
      }
      button,
      svg {
        &:hover {
          cursor: pointer;
          h3 {
            color: var(--titan-blue-3);
          }
          svg path {
            fill: var(--titan-blue-3);
          }
        }
      }
      svg {
        margin-right: 0;
        width: 21px;
      }
      .icon-appstore {
        cursor: pointer;
        width: 20px;
        height: 20px;
        &:hover {
          fill: var(--titan-blue-3);
        }
      }
      .footer-next {
        padding-right: 0;
      }
    }
    hr {
      background: var(--grey-3);
      border: none;
      height: 1px;
      margin: 20px 0;
      width: 100%;
    }
  }
  @media (max-width: 1280px) {
    height: 450px;
    .content-container {
      height: 400px;
    }
    ${({ active }) => {
      if (!active) {
        return `
        bottom: -500px;
      `;
      }
    }}
  }
  @media (max-width: 950px) {
    height: 500px;
    .content-container {
      height: 450px;
    }
    ${({ active }) => {
      if (!active) {
        return `
        bottom: -500px;
      `;
      }
    }}
  }
  @media (max-width: 1000px) {
    .styled-rich-text {
      p {
        font-size: var(--font-size-18);
      }
    }
  }
  ${({ active, isFooterClick }) => {
    if (!active) {
      return `
        bottom: -500px;
      ${!isFooterClick && 'transition: bottom 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);'}
      `;
    }
    return `
      bottom: 0;
      ${!isFooterClick && 'transition: bottom 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);'}
    `;
  }}
`;

export { FeatureDetailContainer };
